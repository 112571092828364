import { ReactElement } from 'react';
import { RootState, Hotel } from '../../../store';
import { getHotelTemplate } from '../../../store/templates';
import PickerOption from '../../Pickers/PickerOption';
import { selectHotels } from '../../../store/staticData/selectors';
import { CabModal } from '@CabComponents/CabModal';
import { CabButton } from '@CabComponents/CabButton';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';



interface ComponentProps {
  showModal: boolean;
  selectHotel: (hotel: Hotel) => void;
  cancelAddHotel: () => void;
}

type Props = ComponentProps;

export const HotelPicker = ({ showModal, selectHotel, cancelAddHotel}: Props): ReactElement => {

  const hotels = useSelector((state: RootState) =>  selectHotels(state.staticData));

  const handleSelectHotel = (id: string | number): void => {
    if (id !== -1) {
      const hotel = hotels.filter((curHotel) => curHotel.id === id);
      selectHotel(hotel[0]);
    } else {
      selectHotel(getHotelTemplate());
    }
  };

  const handleCancel = (): void => {
    cancelAddHotel();
  };


  return (
    <CabModal
      open={showModal}
      onClose={handleCancel}
      closeIcon={true}
      closeOnBackdropClick
      title={'Add Hotel'}
      actionButtons={
        <CabButton buttonType='secondary' onClick={cancelAddHotel}>
          Cancel
        </CabButton>
      }
      sx={{ 
        '& .MuiDialogActions-root':{
          display: 'flex',
          justifyContent: 'flex-start'
        }
      }}
    >
      <Grid container rowGap={2}>
        {hotels.map((hotel): ReactElement =>
          <Grid item xs={4} key={hotel.id}>
            <PickerOption src={hotel.icon_url} id={hotel.id}
              onClick={handleSelectHotel} label={hotel.name} isDefault={hotel.is_default}/>
          </Grid>
        )}
        <Grid item xs={4}>
          <PickerOption src='' id={-1} onClick={handleSelectHotel} label="Other" 
            isDefault={false}/>
        </Grid>
      </Grid>
    </CabModal>
  );
};

export default HotelPicker;
