import { ReactElement, useState, useEffect, useMemo, useCallback } from 'react';
import { actions, RootState, ThunkDispatchType, Hotel, HotelNote, AnyNote } from '../store';
import { InfoCardFields } from '../utils/types';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import CardsPageHeader from '../components/Common/CardsPageHeader';
import NoteOnboarding from '../components/NoteOnboarding';
import { NoteType, PAGE_URL, EVENT_TYPE, EXEC_CARD_TYPES } from '../constants';
import { getHotelNoteTemplate } from '../store/templates';
import HotelPicker from '../components/InfoCard/HotelCard/HotelPicker';
import InfoCardContainer from '../components/InfoCard/InfoCardContainer';
import { trackEventWithExtra } from '../utils/appAnalyticsUtils';
import { CABINET_ICONS, EMOJIS } from '../icons';
import { NO_NOTES_GRAPHIC_SRC } from '../resourceUrls';
import CabinetPage from '../components/Common/CabinetPage';
import { CabButton, CabIcon } from '@CabComponents';
import { CabModal } from '@CabComponents/CabModal';
import { Box, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CabSpinner from '@CabComponents/CabSpinner';
import { IoArrowBack } from 'react-icons/io5';


const hotelCopy = {
  title: 'Let\'s quickly add some hotel reward programs',
  icon: EMOJIS.HOTELS,
  icon_url: CABINET_ICONS.HOTEL.icon_url,
  listCaption: 'Here\'s what we\'ll help you access from your phone or computer:',
  info: [
    'Reward program credentials',
    'Hotel reservation phone numbers',
    'Hotel booking websites'
  ]
};


type RouteParams = { leaderId: string; };


export const Hotels = (): ReactElement => {

  const leaders = useSelector((state: RootState) =>  state.leaders);
  const hotels = useSelector((state: RootState) =>  state.hotels);

  const dispatch = useDispatch<ThunkDispatchType>();
  const fetchHotelNotes = useCallback((leaderId: number) => 
    dispatch(actions.hotels.fetchHotelNotes(leaderId)), [dispatch]);
  const createHotelNote = (note: HotelNote) => dispatch(actions.hotels.createHotelNote(note));
  const updateHotelNote = (note: HotelNote) => dispatch(actions.hotels.updateHotelNote(note));
  const deleteHotelNote = (noteId: number) => dispatch(actions.hotels.deleteHotelNote(noteId));

  const [newHotelNote, setNewHotelNote] = useState<HotelNote>(getHotelNoteTemplate());
  const [idToDelete, setIdToDelete] = useState(-1);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [addHotelMode, setAddHotelMode] = useState(false);
  const [addHotelModalOpen, setAddHotelModalOpen] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);

  const params = useParams<RouteParams>();
  const navigate = useNavigate();
  const location = useLocation();

  const currentLeader = useMemo(() => {
    const foundLeader = leaders.leaders.find(leader => leader.id.toString() === params.leaderId);
    if (leaders.loaded && !foundLeader) {
      navigate(PAGE_URL.DASHBOARD);
    }
    return foundLeader;
  }, [leaders.leaders, leaders.loaded, params.leaderId, navigate]);

  useEffect((): void => {
    if (currentLeader && currentLeader.id > 0) {
      fetchHotelNotes(currentLeader.id);
    }
  }, [fetchHotelNotes, currentLeader, navigate]);

  useEffect((): void => {
    const matchUrl = matchPath(location.pathname, "/executive/:id/hotels");
    // checking URL to hotfix modal opening when it shouldn't
    if (hotels.loaded && hotels.notes.length === 0 && matchUrl) {
      setShowOnboarding(true);
    }
  }, [hotels.loaded, hotels, location.pathname]);

  const loaded = hotels.loaded;
  // Don't show notes while switching leaders
  const showNotes = 
    currentLeader && loaded && hotels.notes.length > 0 && hotels.notes[0].leader === currentLeader.id;


  const handleOpenDeleteAlert = (hotelNoteId: number): (() => void) => 
    (): void => {
      setIdToDelete(hotelNoteId);
      setDeleteAlertOpen(true);
    };

  const handleConfirmDelete = (): void => {
    setDeleteAlertOpen(false);
    deleteHotelNote(idToDelete);
    trackEventWithExtra({ eventName: EVENT_TYPE.DELETE_EXEC_CATEGORY_CARD, extra: {category: EXEC_CARD_TYPES.HOTELS} });
  };

  const handleOpenAddModal = (): void => {
    setAddHotelModalOpen(true);
  };

  const handleCloseAddModal = (): void => {
    setAddHotelModalOpen(false);
  };

  const handleSelectNewHotel = (hotel: Hotel): void => {
    setNewHotelNote({...newHotelNote, hotel_data: hotel});
    setAddHotelModalOpen(false);
    setAddHotelMode(true);
  };

  const handleCancelAddHotel = (): void => {
    setAddHotelMode(false);
  };

  const handleSaveHotelNote = (hotelNote: AnyNote): Promise<void> => {
    if (addHotelMode && currentLeader) {
      trackEventWithExtra({ 
        eventName: EVENT_TYPE.CREATE_EXEC_CATEGORY_CARD, 
        extra: {category: EXEC_CARD_TYPES.HOTELS} 
      });
      return createHotelNote({...hotelNote as HotelNote, leader: currentLeader.id})
        .then((): void => {
          setAddHotelMode(false);
        });
    } else {
      trackEventWithExtra({ 
        eventName: EVENT_TYPE.UPDATE_EXEC_CATEGORY_CARD, 
        extra: {category: EXEC_CARD_TYPES.HOTELS} 
      });
      return updateHotelNote(hotelNote as HotelNote);
    }
  };

  const handleOnboardingClose = (): void => {
    setShowOnboarding(false);
  };

  const infoFields: InfoCardFields<HotelNote>[] = [
    {label: 'Loyalty #', key: 'loyalty_number'},
    {label: 'Username', key: 'username'}, 
    {label: 'General Notes', key: 'notes'}
  ];

  const hasEditPermission = currentLeader?.permissions.standard.hotels.edit;
  
  const renderHotelCards = (): ReactElement => {
    return (
      <Grid container spacing={2}>
        <Grid item hidden={true}>
          <InfoCardContainer<HotelNote> note={newHotelNote} saveNote={handleSaveHotelNote} addMode={addHotelMode} 
            title="Hotel" openDeleteAlert={handleOpenDeleteAlert(newHotelNote.id)} noteType={NoteType.HOTEL} 
            infoFields={infoFields} cancelAdd={handleCancelAddHotel} currentLeader={currentLeader}/>
        </Grid>
        {showNotes && hotels.notes.map((hotelNote: HotelNote): ReactElement =>
          <Grid item xs={12} md={6} key={hotelNote.id}>
            <Box height='100%'>
              <InfoCardContainer<HotelNote> note={hotelNote} saveNote={handleSaveHotelNote} addMode={false} 
                title="Hotel" openDeleteAlert={handleOpenDeleteAlert(hotelNote.id)} noteType={NoteType.HOTEL} 
                infoFields={infoFields} currentLeader={currentLeader}/>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderPlaceholder = (): ReactElement | null => {
    if (loaded && hotels.notes.length === 0) {
      return (
        <Box display='flex' width='100%' flexDirection='column' height="100%" alignItems="center" 
          justifyContent="center" gap={1}>
          <Box component={'img'} src={NO_NOTES_GRAPHIC_SRC} alt="No notes yet" />
          <Typography variant='h5'>No Hotels have been added yet</Typography>
          {hasEditPermission &&
             <CabButton onClick={handleOpenAddModal}>
               Add your first Hotel
             </CabButton>
          }
        </Box>
      );
    } else {
      return null;
    }
  };

  const onBack = (): void => navigate(`${PAGE_URL.EXECUTIVE}/${currentLeader?.id}`);

  const renderSpinnerOrContent = (): ReactElement => {
    if (!loaded) {
      return <Box display='flex' height="100%" alignItems="center" justifyContent="center">
        <CabSpinner scale={4}/>
      </Box>;
    } else {
      return (
        <>
          <Box width='100%' display='flex' flexDirection='column' padding={2} gap={2}>
            {currentLeader && (
              <Box width='100%' display='flex'>
                <CabButton
                  onClick={onBack}
                  icon={<CabIcon Icon={IoArrowBack} />}
                  buttonType="tertiary"
                  color="accent"
                  size="small"
                >
                  Back
                </CabButton>
              </Box>
            )}
            <Box>
              {renderPlaceholder()}
              {renderHotelCards()}
            </Box>
          </Box>
          <CabModal
            open={deleteAlertOpen}
            onClose={() => setDeleteAlertOpen(false)}
            isAlert
            closeIcon
            title='Warning'
            text='Are you sure you want to delete this hotel?'
            noFullScreen
            actionButtons={
              <>
                <CabButton buttonType='secondary' onClick={() => setDeleteAlertOpen(false)}>
                  Cancel
                </CabButton>
                <CabButton onClick={handleConfirmDelete}>
                  Yes, delete it
                </CabButton>
              </>
            }
          />
          <HotelPicker showModal={addHotelModalOpen} selectHotel={handleSelectNewHotel} 
            cancelAddHotel={handleCloseAddModal} />
        </>
      );
    }
  };

  return (
    <CabinetPage
      pageName={'ExecHotels'}
      headerContent={<>
        {currentLeader && 
            <CardsPageHeader 
              title="Hotels" 
              addCard={hasEditPermission ? handleOpenAddModal : undefined} 
            />
        }
      </>}
    >
      {renderSpinnerOrContent()}
      <CabModal
        closeOnBackdropClick
        open={showOnboarding}
        onClose={handleOnboardingClose}
        title={(
          <Box display="flex" alignItems="center" gap={1} paddingTop={2}>
            <Box component={'img'} src={hotelCopy.icon_url} alt={'hotel'} height={40} />
            {hotelCopy.title}
          </Box>
        )}
        actionButtons={
          <CabButton size="large" buttonType="primary" onClick={handleOnboardingClose}>
            Got it!
          </CabButton>
        }
        sx={{ ".MuiPaper-root": { minWidth: '500px' } }}
      >
        <NoteOnboarding copy={hotelCopy} />
      </CabModal>

    </CabinetPage>
  );
};

export default Hotels;